import './showlessons.css';
import './home.css';
import { useState, useEffect, useRef } from 'react';
import certificate from '../header/images/certificate.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSwatchbook, faChevronDown, faLock, faClipboardCheck, faLockOpen, faGripLines } from '@fortawesome/free-solid-svg-icons';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import pic1 from '../imagesHome/calc.png';
import { faWebAwesome } from '@fortawesome/free-brands-svg-icons'; 
import HeaderLessons from './HeaderLessons';
import LessonProgressLesson from './progresbars/LessonProgressLesson';

export const Showlessons = () => {
  const [showLessonsMain, setShowLessonsMain] = useState(true);
  const [showChapterOne, setShowChapterOne] = useState(false);
  const [activePanel, setActivePanel] = useState(null);
  const panelRef = useRef(null);
  const lessonButtonRef = useRef(null); // Referință pentru butonul de lecție activă

  const unhideLessons = () => setShowLessonsMain(!showLessonsMain);
  const showingChapterOne = () => setShowChapterOne(!showChapterOne);

  const location = useLocation();
  const { updatedProgress } = location.state || {};
  
  const tothelesson = useNavigate();

  const [lessonProgress, setLessonProgress] = useState({
    1: { unlocked: true, completed: false },
    2: { unlocked: true, completed: false },
    3: { unlocked: false, completed: false },
    4: { unlocked: false, completed: false },
    5: { unlocked: false, completed: false },
  });

  useEffect(() => {
    if (updatedProgress) {
      setLessonProgress(prevProgress => ({
        ...prevProgress,
        ...updatedProgress
      }));
    }
  }, [updatedProgress]);

  const handleLessonClick = (chapterId, lessonId) => {
    if (lessonProgress[lessonId]?.unlocked) {
      tothelesson('/chaptercontainer', { state: { chapterId, lessonId } });
    } else {
      alert('Această lecție este blocată. Finalizează lecția anterioară pentru a o debloca.');
    }
  };

  // Toggle panoul la click pe lecție și face scroll la panou dacă este deschis
  const togglePanel = (lessonId, event) => {
    setActivePanel(prevPanel => (prevPanel === lessonId ? null : lessonId));
    lessonButtonRef.current = event.currentTarget; // Salvează elementul lecției active

    // Așteaptă deschiderea panoului și face scroll pentru a-l aduce în vizor
    setTimeout(() => {
      if (panelRef.current && lessonId !== activePanel) {
        panelRef.current.scrollIntoView({
          behavior: "smooth",
          block: "center" // Poziționează panoul la centrul ferestrei
        });
      }
    }, 100); // Mic delay pentru a asigura afișarea completă înainte de scroll
  };

  // Închide panoul activ la click în afara acestuia
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        panelRef.current && !panelRef.current.contains(event.target) &&
        lessonButtonRef.current && !lessonButtonRef.current.contains(event.target)
      ) {
        setActivePanel(null);
      }
    };

    if (activePanel !== null) {
      document.addEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [activePanel]);

  const lessons = [
    { id: 1, title: "Scopul contabilitatii", icon: faLock },
    { id: 2, title: "Scopul contabilitatii", icon: faWebAwesome },
    { id: 3, title: "Scopul contabilitatii", icon: faLockOpen },
    { id: 4, title: "Scopul contabilitatii", icon: faWebAwesome },
    { id: 5, title: "Scopul contabilitatii", icon: faLockOpen },
    { id: 6, title: "Practica", icon: faClipboardCheck },
  ];

  return (
    <>
      <HeaderLessons />
      <div className='container-show-lessons'>
        <div className="colored-header-lesson py-4 rounded-4 d-flex flex-column justify-content-center align-items-center" onClick={unhideLessons}>
          <div className='d-flex flex-row'>
            <FontAwesomeIcon icon={faSwatchbook} className='dimension-headers-lessons text-white mx-4 p-2 rounded-4' />
            <div className='text-white fs-4 fw-bold mx-1'>Bazele contabilitatii</div>
            <FontAwesomeIcon icon={faChevronDown} className={`text-white mx-4 p-2 rounded-4 ${showLessonsMain ? 'dimension-headers-lessons-1' : 'dimension-headers-lessons-rotate'}`} />
          </div>
          <div className='text-lesson-presentation'>
            <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quia officia, unde molestias ratione fugiat repudiandae laborum repellendus? Dolor illo, error, impedit culpa fugit, quis dicta amet repellat ullam nihil eveniet?</p>
          </div>
          <LessonProgressLesson />
        </div>

        {showLessonsMain && (
          <div>
            <div className='line-lesson-gackground'>
              <div className='chapter-container' onClick={showingChapterOne}>
                <FontAwesomeIcon icon={faLockOpen} style={{ color: 'white', width: '20px', height: '20px' }} />
                <span>Capitol</span>
                <FontAwesomeIcon icon={faGripLines} className={showChapterOne ? 'dbl-line-vertical' : 'dbl-line-horizontal'} style={{ color: 'white', width: '25px', height: '25px' }} />
              </div>

              <div className={showChapterOne ? 'displaying-none-chapters' : 'displaying-show-chapters'}>
                {lessons.map((lesson) => (
                  <div key={lesson.id}>
                    <div
                      className='lesson-show-main'
                      onClick={(e) => togglePanel(lesson.id, e)}
                    >
                      <div className='symbol-lesson'>
                        <div className='align-items-lessons-inside-show'>
                          <div className='item-lesson-circle'>
                            <img src={pic1} alt='img' className='symbol-item-lesson' />
                          </div>
                          <div className='items-text-lesson'>
                            <span>{lesson.title}</span>
                            <span>Hello, this is a simple text</span>
                          </div>
                        </div>
                        <FontAwesomeIcon icon={lesson.icon} style={{ color: 'rgb(22, 199, 158)', width: '20px', height: '20px' }} />
                      </div>
                    </div>

                    {activePanel === lesson.id && (
                      <div className="panel-detail-leson" ref={panelRef}>
                        <div className="detail-panel-overlay">
                          <div className="detail-panel-content">
                            <div className='content-panel-details'>
                              <p>Titlul lectiei venit din baza de date</p>
                              <div>
                                <button onClick={() => handleLessonClick(1, 1)}>Refa intrebarile</button>
                              </div>
                              <button className='costomize-start-lesson' onClick={() => handleLessonClick(1, 1)}>Incepe lectia</button>
                              
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}

        <div className='obtain-certificate'>
          <img src={certificate} alt="certificate" className="disabled-image" />
          <h4>Obtine diploma de bazele contabilitii</h4>
          <p>Te descurci bine, continua pana cand optii diploma!</p>
          <button className='buton-diploma'>Revendica-ti diploma</button>
        </div>
        <Link to='/diploma'>Diploma</Link>
      </div>
    </>
  );
};
