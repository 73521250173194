// GoogleLogin.js
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGoogle, faFacebook } from '@fortawesome/free-brands-svg-icons';
import './registrer.css';

  // Adaugă URL-ul API-ului folosind variabila de mediu
  const apiUrl = process.env.REACT_APP_API_URL;


const SocialLogin = () => {
  const handleGoogleLogin = () => {
    window.location.href = `${apiUrl}/auth/google`;
  };

  const handleFacebookLogin = () => {
    window.location.href = `${apiUrl}/auth/facebook`;
  };

  return (
    <div className="options-connect">
      <div className="connect-social" onClick={handleGoogleLogin}>
        <FontAwesomeIcon icon={faGoogle} style={{ display: 'block' }} />
        <div>Google</div>
      </div>
      <div className="connect-social" onClick={handleFacebookLogin}>
        <FontAwesomeIcon icon={faFacebook} style={{ display: 'block' }} />
        <div>Facebook</div>
      </div>
    </div>
  );
};

export default SocialLogin;
