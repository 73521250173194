import './logo.css';
import logo from './images/logo.svg';
import { Link } from 'react-router-dom';
import { useContext } from 'react';
import { AuthContext } from '../AuthContext'; // Importăm contextul de autentificare

export const Logo = () => {
  const { isAuthenticated } = useContext(AuthContext); // Verificăm dacă utilizatorul este autentificat

  return (
    <div className='logo'>
      {/* Dacă utilizatorul este autentificat, logo-ul duce la /userpage, altfel la / */}
      <Link to={isAuthenticated ? '/userpage' : '/'}>
        <img src={logo} alt="logo" />
      </Link>
    </div>
  );
};
