const Politicacookies = () => {
  return (

    <div className='general-container-footer-info'>
    <div className='info-footer-container'>
   
      <h1>Politica de Cookie-uri</h1>
      
      <h2>Ce sunt cookie-urile?</h2>
      <p>
        Un cookie este un fișier mic de text pe care un site web îl salvează pe computerul sau pe dispozitivul mobil al utilizatorului atunci când acesta vizitează site-ul. Cookie-urile sunt utilizate pe scară largă pentru a face ca site-urile web să funcționeze sau să funcționeze mai eficient, precum și pentru a oferi informații proprietarilor site-ului.
      </p>
      
      <h2>Ce tipuri de cookie-uri utilizăm?</h2>
      
      <h3>Cookie-uri esențiale</h3>
      <p>
        Aceste cookie-uri sunt strict necesare pentru funcționarea site-ului nostru. Fără aceste cookie-uri, anumite părți ale site-ului nu vor funcționa corect. De exemplu, cookie-urile de autentificare care permit utilizatorilor să se conecteze la conturile lor sunt cookie-uri esențiale.
      </p>
      
      <h3>Cookie-uri de performanță</h3>
      <p>
        Aceste cookie-uri colectează informații despre modul în care vizitatorii folosesc site-ul nostru, cum ar fi ce pagini sunt accesate cel mai frecvent. Aceste cookie-uri ne ajută să îmbunătățim performanța site-ului și experiența utilizatorului.
      </p>
      
      <h3>Cookie-uri de funcționalitate</h3>
      <p>
        Aceste cookie-uri permit site-ului să memoreze alegerile pe care le faceți (cum ar fi numele de utilizator, limba sau regiunea) și să ofere caracteristici îmbunătățite și mai personalizate. De exemplu, un cookie poate memora preferințele dvs. de limbă sau poate salva sesiunea dvs. activă.
      </p>
      
      <h3>Cookie-uri de targetare și publicitate</h3>
      <p>
        Aceste cookie-uri sunt utilizate pentru a vă oferi conținut publicitar relevant pe baza intereselor dvs. Aceste cookie-uri pot înregistra informații despre vizita dvs. pe site-ul nostru și pe alte site-uri și sunt utilizate pentru a adapta publicitatea la interesele dvs.
      </p>
      
      <h2>Cum utilizăm cookie-urile?</h2>
      <p>
        Folosim cookie-uri pentru a:
      </p>
      <ul>
        <li>Personaliza experiența utilizatorilor.</li>
        <li>Analiza modul în care utilizatorii navighează pe site-ul nostru, pentru a ne îmbunătăți serviciile.</li>
        <li>Asigura funcționalitatea de bază a site-ului nostru, cum ar fi autentificarea utilizatorilor și memorarea preferințelor acestora.</li>
      </ul>
      
      <h2>Cookie-urile terților</h2>
      <p>
        Pe lângă cookie-urile setate de noi, pot exista și cookie-uri plasate de terți. De exemplu, folosim cookie-uri de la servicii precum Google Analytics pentru a colecta informații despre traficul pe site. Aceste cookie-uri sunt plasate de furnizorii de servicii terți și nu colectăm noi informațiile respective direct.
      </p>
      
      <h2>Cum puteți controla cookie-urile?</h2>
      <p>
        Majoritatea browserelor web permit controlul cookie-urilor prin setările acestora. Aveți posibilitatea de a șterge cookie-urile sau de a seta browserul să blocheze cookie-urile. Cu toate acestea, blocarea cookie-urilor esențiale poate afecta funcționarea corectă a anumitor funcții ale site-ului.
      </p>

      <h3>Cum să gestionați cookie-urile în browser:</h3>
      <ul>
        <li><b>Google Chrome:</b> Mergi la Setări {'>'} Confidențialitate și securitate {'>'} Setări pentru site-uri {'>'} Cookie-uri.</li>
        <li><b>Mozilla Firefox:</b> Mergi la Setări {'>'} Confidențialitate și securitate {'>'} Cookie-uri și date de site-uri.</li>
        <li><b>Microsoft Edge:</b> Mergi la Setări {'>'} Permisiuni pentru site-uri {'>'} Gestionare și ștergere cookie-uri.</li>
        <li><b>Safari:</b> Mergi la Setări {'>'} Confidențialitate {'>'} Gestionare cookie-uri.</li>
      </ul>

      <h2>Schimbări în politica de cookie-uri</h2>
      <p>
        Putem actualiza această politică de cookie-uri periodic, așa că vă rugăm să consultați această pagină ocazional pentru a fi la curent cu orice modificări. Data ultimei actualizări a politicii de cookie-uri este [Introduceți data].
      </p>
    </div>
    </div>
  );
};

export default Politicacookies;
