import './cursuri.css';
import { Link } from 'react-router-dom';
const Demo = () => {
  return (
    <div className="container-select-course">
        <div className="item-course">
            <h3 className="mb-4">Demo</h3>
            <Link to='/showlessons'>
                <button className='select-course'>Incearca lectia demo</button>
            </Link>
        </div>
        
    </div>
  )
}

export default Demo