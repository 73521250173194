import React, { useEffect, useState } from 'react';
import './statusprogres.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCoins } from '@fortawesome/free-solid-svg-icons';
import { faHeart as faHeartSolid } from '@fortawesome/free-solid-svg-icons';
import { faCircleQuestion } from '@fortawesome/free-regular-svg-icons';

const StatusProgres = ({ 
  width, 
  onExitClick, 
  coinCount, 
  showHeartPopup, 
  heartPopup, 
  onHeartClick, 
  onEyeClick, 
  showEyePopup, 
  eyePopup 
}) => {
  const [progress, setProgress] = useState(width);
  const [displayedCoinCount, setDisplayedCoinCount] = useState(coinCount);
  const [isCoinAnimating, setIsCoinAnimating] = useState(false);

  useEffect(() => {
    setProgress(width);
  }, [width]);

  useEffect(() => {
    if (coinCount > displayedCoinCount) {
      setIsCoinAnimating(true);
      const timer = setTimeout(() => {
        setIsCoinAnimating(false);
        setDisplayedCoinCount(coinCount);
      }, 300);

      return () => clearTimeout(timer);
    }
  }, [coinCount, displayedCoinCount]);

  return (
    <div className='general-section-progress-bar'>
      <div className='first-elements-header-progress-bar'>
        <div className='exit-lesson-name dim-prog-bar-1'>
          <FontAwesomeIcon
            icon={faTimes}
            className='arrow-change-lesson-x'
            onClick={onExitClick}
          />
        </div>
        <div className='dim-prog-bar-2'>
          <div className="heart-container" onClick={onHeartClick}>
            <FontAwesomeIcon icon={faHeartSolid} className="heart-icon" />
            <span className="score-text">5</span>
          </div>

          <div className="eye-container" onClick={onEyeClick}>
            <FontAwesomeIcon icon={faCircleQuestion} className="eye-icon" />
          </div>
        </div>

        <div className='procent-lesson dim-prog-bar-3'>
          <div className={`dim-prog-bar ${isCoinAnimating ? 'bounce-animation' : ''}`}>
            <span className="coin-score-box">{displayedCoinCount}</span>
            <FontAwesomeIcon icon={faCoins} className="coin-edit-score" />
          </div>
        </div>
      </div>

      <div className='section-progress-bar-lesson'>
        <div className='progress-bar-container'>
          <input
            type='range'
            min='0'
            max='100'
            value={progress}
            className='progress-bar-range'
            style={{ '--progress': `${progress}%` }}
            disabled
          />
        </div>
      </div>

      {showHeartPopup && heartPopup}
      {showEyePopup && eyePopup}
    </div>
  );
};

export default StatusProgres;
