import './footer.css';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons'; 
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons';

export const Footer = () => {
  return (
  
    <div className='footer'>
     
      <div className='d-flex justify-content-center align-items-center mt-4'>
        <div className='border-social-facebook'>
          <FontAwesomeIcon icon={faFacebookF} className='facebook ' />
        </div>
        <div className='border-social-instagram'>
        <FontAwesomeIcon icon={faInstagram} className='instagram' />
        </div>
        <div className='border-social-linkedin'>
        <FontAwesomeIcon icon={faLinkedinIn} className='linkedin'/>
        </div>
      </div>
      <div className='w-100 my-3' style={{height: '1px', backgroundColor: 'gray', opacity: 0.3}}>Line</div>
      <div className='footer-menu'>
        <Link to='/' className='links-footer'>
          <div className='item-fooyer-menu'>Home</div>
        </Link>
        <Link to='about' className='links-footer'>
          <div className='item-fooyer-menu'>Despre</div>
        </Link>
        <Link to='FAQ' className='links-footer'>
          <div className='item-fooyer-menu'>FAQ</div>
        </Link>
        <Link to='/ajutor' className='links-footer'>
          <div className='item-fooyer-menu'>Contact</div>
        </Link>
        <Link to='Termeni' className='links-footer'>
          <div className='item-fooyer-menu'>Termeni si conditii</div>
        </Link>
        <Link to='Politica' className='links-footer'>
          <div className='item-fooyer-menu'>Politica de confidentialitate</div>
        </Link>
        <Link to='/Politicacookies' className='links-footer'>
          <div className='item-fooyer-menu'>Politica de Cookie-uri</div>
        </Link>
      </div>
      <div className='w-100 my-3' style={{height: '1px', backgroundColor: 'gray', opacity: 0.3}}>Line</div>
      <p>© 2024 Toate drepturile rezervate <Link to='/' className='reserved-rights'>contalearn.ro</Link></p>
    </div>
  );
};
