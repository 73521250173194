import './userpage.css';
import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart, faCoins, faMedal } from '@fortawesome/free-solid-svg-icons';
import { faUser as faUserRegular } from '@fortawesome/free-regular-svg-icons';
import baze from '../header/images/baze.png';
import fnanciara from '../header/images/fnanciara.png';
import raportare from '../header/images/raportare.png';
import LessonProgressLesson from '../content/progresbars/LessonProgressLesson';
import { Link } from 'react-router-dom';

// URL-ul API-ului din variabila de mediu
const apiUrl = process.env.REACT_APP_API_URL;

const UserPage = () => {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await fetch(`${apiUrl}/user`, {
          method: 'GET',
          credentials: 'include',
        });
        if (response.ok) {
          const data = await response.json();
          setUserData(data);
        } else {
          console.error('Eroare la preluarea datelor utilizatorului.');
        }
      } catch (error) {
        console.error('Eroare la preluarea datelor utilizatorului:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, []);

  if (loading) {
    return <div>Se încarcă datele utilizatorului...</div>;
  }

  if (!userData) {
    return <div>Eroare la încărcarea datelor utilizatorului</div>;
  }

  return (
    <div className='user-background-info'>
      <div id='user-page'>
        <div className='user-section'>
          <div className='user-image'>
            {userData.profile_picture ? (
              <img src={userData.profile_picture} alt="user" />  // Afișează poza din baza de date
            ) : (
              <FontAwesomeIcon icon={faUserRegular} className="default-profile-icon" size="6x" /> // Icon implicit de profil
            )}
          </div>
          <div className='user-datas-dashbord'>
            <h1 className='data-color-user'>{userData.username}</h1>
            <h3 className='data-color-user'>{userData.email}</h3>
            <h3 className='data-color-user'>Premium</h3>
          </div>
        </div>
      </div>
      <div className='user-info-page'>
        <div className='item-info-user-one'>
          <h3 style={{ padding: '0px', textAlign: 'center', color: 'white' }}>Progres cursuri</h3>
          <Link to='/showlessons' className='cadru-progres-dashbord-baze'>
            <h4>Bazele contabilitatii</h4>
            <LessonProgressLesson /> 
          </Link>

          <div className='cadru-progres-dashbord-financiara'>
            <h4>Contabilitate financiara</h4>
            <LessonProgressLesson />  
          </div>

          <div className='cadru-progres-dashbord-raportare'>
            <h4>Raportari financiare</h4>
            <LessonProgressLesson />  
          </div>
        </div>
        <div className='align-dashbord-elements'>
          <div id='lectii-certificate'>
            <h3 className='item-info-user'>
              <p style={{ padding: '10px', textAlign: 'center', color: 'white' }}>Certificate obtinute</p>
              <div className='cadru-certificate'>
                <div className='d-flex flex-column justify-content-center align-items-center'>
                  <div className='cadru-item-certificate-baze'>
                    <img src={baze} alt='Bazele contabilitatii' className='item-certificate' />
                  </div>
                  <div className='d-flex justify-content-center align-items-center my-2'>
                      <div className='point-certificate-1'></div>
                      <div className='point-certificate-2'></div>
                      <div className='point-certificate-2'></div>
                  </div>
                  <p className='text-diploma'>Bazele contabilitatii</p>
                </div>
                <div className='d-flex flex-column justify-content-center align-items-center'>
                  <div className='cadru-item-certificate-financiara'>
                    <img src={fnanciara} alt='Contabilitate financiara' className='item-certificate' />
                  </div>
                  <div className='d-flex justify-content-center align-items-center my-2'>
                      <div className='point-certificate-1'></div>
                      <div className='point-certificate-1'></div>
                      <div className='point-certificate-2'></div>
                  </div>
                  <p className='text-diploma'>Contabilitate financiara</p>
                </div>
                <div className='d-flex flex-column justify-content-center align-items-center'>
                  <div className='cadru-item-certificate-raportare'>
                    <img src={raportare} alt='Raportare financiara' className='item-certificate' />
                  </div>
                  <div className='d-flex justify-content-center align-items-center my-2'>
                      <div className='point-certificate-1'></div>
                      <div className='point-certificate-1'></div>
                      <div className='point-certificate-1'></div>
                  </div>
                  <p className='text-diploma'>Raportare financiara</p>
                </div>
              </div>
            </h3>
          </div>
          <div id='punctaj-user'>
            <div className='item-dashbord'>
              <FontAwesomeIcon icon={faCoins} className='size-item-dashbord' />
              <div className='cadru-puncte-dashbord-candh'>120</div>
            </div>
            <div className='item-dashbord'>
              <FontAwesomeIcon icon={faHeart} className='size-item-dashbord' />
              <div className='cadru-puncte-dashbord-candh'>20</div>
            </div>
            <div className='item-dashbord'>
              <FontAwesomeIcon icon={faMedal} className='size-item-dashbord' />
              <button className='buton-medalii'>Medalii obtinute</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserPage;
