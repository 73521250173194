import React, { useState } from 'react';
import axios from 'axios';
import './premium.css';
import Logo from '../../header/images/logo-contalearn.png';
import { useNavigate } from 'react-router-dom'; 
import ExitOffer from './ExitOffer';


// Adaugă URL-ul API-ului folosind variabila de mediu
const apiUrl = process.env.REACT_APP_API_URL;


// Liste de județe și orașe asociate
const judete = [
  { name: 'Alba', orase: ['Alba Iulia', 'Aiud', 'Blaj', 'Cugir', 'Ocna Mureș', 'Teiuș', 'Zlatna'] },
  { name: 'Arad', orase: ['Arad', 'Chișineu-Criș', 'Curtici', 'Ineu', 'Lipova', 'Nădlac', 'Pâncota', 'Pecica', 'Sântana', 'Sebiș'] },
  { name: 'Argeș', orase: ['Pitești', 'Costești', 'Câmpulung', 'Mioveni', 'Ștefănești', 'Topoloveni'] },
  { name: 'Bacău', orase: ['Bacău', 'Buhusi', 'Comănești', 'Dărmănești', 'Onești', 'Slănic-Moldova', 'Târgu Ocna'] },
  { name: 'Bihor', orase: ['Oradea', 'Alesd', 'Beiuș', 'Marghita', 'Nucet', 'Salonta', 'Săcueni', 'Ștei', 'Valea lui Mihai', 'Vascau'] },
  { name: 'Bistrița-Năsăud', orase: ['Bistrița', 'Beclean', 'Năsăud', 'Sângeorz-Băi'] },
  { name: 'Botoșani', orase: ['Botoșani', 'Bucecea', 'Darabani', 'Săveni', 'Dorohoi', 'Flămânzi', 'Ștefănești'] },
  { name: 'Brașov', orase: ['Brașov', 'Codlea', 'Făgăraș', 'Ghimbav', 'Predeal', 'Râșnov', 'Rupea', 'Săcele', 'Victoria', 'Zărnești'] },
  { name: 'Brăila', orase: ['Brăila', 'Făurei', 'Ianca', 'Însurăței'] },
  { name: 'București', orase: ['Sector 1', 'Sector 2', 'Sector 3', 'Sector 4', 'Sector 5', 'Sector 6'] },
  { name: 'Buzău', orase: ['Buzău', 'Nehoiu', 'Pătârlagele', 'Pogoanele', 'Râmnicu Sărat'] },
  { name: 'Caraș-Severin', orase: ['Reșița', 'Anina', 'Băile Herculane', 'Bocșa', 'Caransebeș', 'Moldova Nouă', 'Oravița', 'Oțelu Roșu'] },
  { name: 'Călărași', orase: ['Călărași', 'Budești', 'Fundulea', 'Lehliu Gară', 'Oltenița'] },
  { name: 'Cluj', orase: ['Cluj-Napoca', 'Câmpia Turzii', 'Dej', 'Gherla', 'Huedin', 'Turda'] },
  { name: 'Constanța', orase: ['Constanța', 'Băneasa', 'Cernavodă', 'Eforie', 'Hârșova', 'Mangalia', 'Medgidia', 'Murfatlar', 'Năvodari', 'Negru Vodă', 'Ovidiu', 'Techirghiol'] },
  { name: 'Covasna', orase: ['Sfântu Gheorghe', 'Baraolt', 'Covasna', 'Târgu Secuiesc'] },
  { name: 'Dâmbovița', orase: ['Târgoviște', 'Fieni', 'Găești', 'Pucioasa', 'Răcari', 'Titu'] },
  { name: 'Dolj', orase: ['Craiova', 'Băilești', 'Calafat', 'Dăbuleni', 'Filiași', 'Segarcea'] },
  { name: 'Galați', orase: ['Galați', 'Berești', 'Târgu Bujor'] },
  { name: 'Giurgiu', orase: ['Giurgiu', 'Bolintin-Vale', 'Mihăilești'] },
  { name: 'Gorj', orase: ['Târgu Jiu', 'Bumbești-Jiu', 'Motru', 'Novaci', 'Rovinari', 'Târgu Cărbunești', 'Țicleni', 'Tismana', 'Turceni'] },
  { name: 'Harghita', orase: ['Miercurea Ciuc', 'Băile Tușnad', 'Borsec', 'Bălan', 'Cristuru Secuiesc', 'Gheorgheni', 'Odorheiu Secuiesc', 'Toplița', 'Vlăhița'] },
  { name: 'Hunedoara', orase: ['Deva', 'Brad', 'Hațeg', 'Hunedoara', 'Orăștie', 'Petrila', 'Petroșani', 'Simeria', 'Uricani', 'Vulcan'] },
  { name: 'Ialomița', orase: ['Slobozia', 'Amara', 'Căzănești', 'Fetești', 'Fierbinți-Târg', 'Țăndărei', 'Urziceni'] },
  { name: 'Iași', orase: ['Iași', 'Hârlău', 'Podu Iloaiei', 'Târgu Frumos', 'Pașcani'] },
  { name: 'Ilfov', orase: ['Buftea', 'Bragadiru', 'Chitila', 'Măgurele', 'Otopeni', 'Pantelimon', 'Popești-Leordeni', 'Voluntari'] },
  { name: 'Maramureș', orase: ['Baia Mare', 'Borșa', 'Cavnic', 'Dragomirești', 'Seini', 'Sighetu Marmației', 'Târgu Lăpuș', 'Ulmeni', 'Vișeu de Sus'] },
  { name: 'Mehedinți', orase: ['Drobeta-Turnu Severin', 'Baia de Aramă', 'Orșova', 'Strehaia', 'Vânju Mare'] },
  { name: 'Mureș', orase: ['Târgu Mureș', 'Iernut', 'Luduș', 'Reghin', 'Sărmașu', 'Sângeorgiu de Pădure', 'Sovata', 'Târnăveni'] },
  { name: 'Neamț', orase: ['Piatra Neamț', 'Bicaz', 'Roman', 'Roznov', 'Târgu Neamț'] },
  { name: 'Olt', orase: ['Slatina', 'Balș', 'Corabia', 'Drăgănești-Olt', 'Piatra-Olt', 'Potcoava', 'Scornicești'] },
  { name: 'Prahova', orase: ['Ploiești', 'Azuga', 'Băicoi', 'Boldești-Scăeni', 'Breaza', 'Bușteni', 'Comarnic', 'Mizil', 'Plopeni', 'Sinaia', 'Slănic', 'Urlați', 'Vălenii de Munte'] },
  { name: 'Satu Mare', orase: ['Satu Mare', 'Ardud', 'Carei', 'Livada', 'Negrești-Oaș', 'Tășnad'] },
  { name: 'Sălaj', orase: ['Zalău', 'Cehu Silvaniei', 'Jibou', 'Șimleu Silvaniei'] },
  { name: 'Sibiu', orase: ['Sibiu', 'Agnita', 'Avrig', 'Cisnădie', 'Copșa Mică', 'Dumbrăveni', 'Miercurea Sibiului', 'Ocna Sibiului', 'Săliște', 'Tălmaciu'] },
  { name: 'Suceava', orase: ['Suceava', 'Broșteni', 'Cajvana', 'Dolhasca', 'Fălticeni', 'Frasin', 'Gura Humorului', 'Liteni', 'Milișăuți', 'Rădăuți', 'Salcea', 'Siret', 'Solca', 'Vicovu de Sus', 'Vatra Dornei'] },
  { name: 'Teleorman', orase: ['Alexandria', 'Roșiorii de Vede', 'Turnu Măgurele', 'Videle', 'Zimnicea'] },
  { name: 'Timiș', orase: ['Timișoara', 'Buziaș', 'Ciacova', 'Deta', 'Făget', 'Gătaia', 'Jimbolia', 'Lugoj', 'Recaș', 'Sânnicolau Mare'] },
  { name: 'Tulcea', orase: ['Tulcea', 'Babadag', 'Isaccea', 'Măcin', 'Sulina'] },
  { name: 'Vâlcea', orase: ['Râmnicu Vâlcea', 'Băbeni', 'Băile Govora', 'Băile Olănești', 'Bălcești', 'Berbești', 'Brezoi', 'Călimănești', 'Horezu', 'Ocnele Mari'] },
  { name: 'Vaslui', orase: ['Vaslui', 'Bârlad', 'Huși', 'Murgeni'] },
  { name: 'Vrancea', orase: ['Focșani', 'Adjud', 'Mărășești', 'Odobești', 'Panciu'] }
];


const Facturare = () => {
  const navigate = useNavigate();
  
  const [billingData, setBillingData] = useState({
    nume: '',
    prenume: '',
    adresa: '',
    oras: '',
    judet: '',
    codPostal: '',
    email: '',
    telefon: '',
  });

  const [error, setError] = useState(null);

  // Funcția care gestionează schimbările din inputuri
  const handleChange = (e) => {
    setBillingData({
      ...billingData,
      [e.target.name]: e.target.value
    });
  };

  // Funcția care actualizează lista de orașe când se selectează un județ
  const handleJudetChange = (e) => {
    const selectedJudet = e.target.value;
    
    setBillingData({
      ...billingData,
      judet: selectedJudet,
      oras: '' // Resetăm câmpul oraș pentru a forța utilizatorul să selecteze manual
    });
  };

  // Funcția pentru a trimite datele către backend
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      // Logare pentru verificarea datelor finale trimise
      console.log('Datele trimise:', billingData);

      // Trimiterea datelor către backend-ul tău (Express server)
      await axios.post(`${apiUrl}/generate-invoice`, { billingData });

      // Redirecționează utilizatorul către pagina de succes
      navigate('/payment-success');
    } catch (error) {
      console.error('Eroare la trimiterea datelor:', error);
      setError('A apărut o eroare la procesarea datelor.');
    }
  };

  return (
    <div className='cadru-premium-step-two'>
      <div className='header-prem-top'>
        <div className='header-premium'>
          <div className='logo-dist-premium'>
            <img src={Logo} alt='logo-premium' className='logo-premuim' />
          </div>
          <ExitOffer />
        </div>
      </div>
      <div className='info-cadru-premium'>
        <div className='w-100'>
          <div className='header-premuim-page'>
            <h1>Detalii facturare</h1>
          </div>

          <div className='billing-style'>
            <form onSubmit={handleSubmit} className='form-billing'>
              
              <input type='text' className='input-billing' name='nume' placeholder='Nume' value={billingData.nume} onChange={handleChange} required />
              
              <input type='text' className='input-billing' name='prenume' placeholder='Prenume' value={billingData.prenume} onChange={handleChange} required />
              
              {/* Dropdown pentru județ */}
              <select name='judet' className='input-billing' value={billingData.judet} onChange={handleJudetChange} required>
                <option value=''>Selectează județ</option>
                {judete.map(j => (
                  <option key={j.name} value={j.name}>{j.name}</option>
                ))}
              </select>

              {/* Dropdown pentru oraș */}
              <select name='oras' className='input-billing' value={billingData.oras} onChange={handleChange} required disabled={!billingData.judet}>
                <option value=''>Selectează oraș</option>
                {judete.find(j => j.name === billingData.judet)?.orase.map(oras => (
                  <option key={oras} value={oras}>{oras}</option>
                ))}
              </select>

              <input type='text' className='input-billing' name='adresa' placeholder='Strada și număr' value={billingData.adresa} onChange={handleChange} required />

              <input type='text' className='input-billing' name='codPostal' placeholder='Cod postal (optional)' value={billingData.codPostal} onChange={handleChange} required />

              <input type='email' className='input-billing' name='email' placeholder='Email' value={billingData.email} onChange={handleChange} required />

              <input type='tel' className='input-billing' name='telefon' placeholder='Telefon (optional)' value={billingData.telefon} onChange={handleChange} />
              
              <button type='submit' className='button-billing'>Mergi către plata</button>
            </form>
            
            {error && <div className='error-message'>{error}</div>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Facturare;
