import React, { useRef } from 'react';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import './diploma.css';
import logo from '../header/images/logo-contalearn.png';




const Diploma = () => {

    const name = 'Alin Dorobantu';
    const course = 'Bazele contabilitatii'

    const diplomaRef = useRef(null);

    const downloadPDF = () => {
        const input = diplomaRef.current;
        html2canvas(input).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF({
                orientation: 'landscape',
                unit: 'px',
                format: [800, 600],
            });
            pdf.addImage(imgData, 'PNG', 0, 0, 800, 600);
            pdf.save(`${name}_diploma.pdf`);
        });
    };

    return (
        <div className='general-diploma'>
            <div className="diploma-container" ref={diplomaRef}>
                <h1>CERTIFICARE</h1>
                <p>Se certifica faptul ca:</p>
                <h2>{name}</h2>
                <p>A absolvit cu succes cursul de:</p>
                <h3>{course}</h3>
                
                <img src={logo} alt='logo' className='logo-diploma' />
                <p>Data: {new Date().toLocaleDateString()}</p>
            </div>
            <button onClick={downloadPDF} className='mt-5'>Descarcă Diploma</button>


        </div>
    );
};

export default Diploma;
