import './premium.css';
import Logo from '../../header/images/logo-contalearn.png';
import { Link } from 'react-router-dom';

import ExitOffer from './ExitOffer';

const Viewplans = () => {
  return (
    <div className='cadru-premium-step-two'>
      <div className='header-prem-top'>
        <div className='header-premium'>
          <div className='logo-dist-premium'>
            <img src={Logo} alt='logo-premium' className='logo-premuim' />
          </div>
          <ExitOffer />
        </div>
      </div>
      <div className='info-cadru-premium'>
        <div className='w-100'>
          <div className='header-premuim-page'>
            <h1>Obtine planul Premium</h1>
          </div>

          <div className='premium-third-page'>
            <div>

            </div>
            <div className='plan-price'>
                    <div className='details-price'>
                        <p>Anual</p>
                        <p>249 lei / an</p>
                        <p>Facturare anuala dupa perioada free</p>
                    </div>
                    <div>pret pe luna</div>
                </div>
                <div className='plan-price'>
                    <div className='details-price'>
                        <p>Anual</p>
                        <p>249 lei / an</p>
                        <p>Facturare anuala dupa perioada free</p>
                    </div>
                    <div>pret pe luna</div>
                </div>
                <div className='plan-price'>
                    <div className='details-price'>
                        <p>Anual</p>
                        <p>249 lei / an</p>
                        <p>Facturare anuala dupa perioada free</p>
                    </div>
                    <div>pret pe luna</div>
                </div> 
          </div>

        </div>
        <Link to='/finaloffer' className='css-link-offer' >
          <button className='button-premium-step-two'>Incepe gratuit</button>
        </Link>
      </div>
    </div>
  )
}

export default Viewplans;