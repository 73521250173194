import './blog.css';
const Blog = () => {
  return (
    <>
    <div>

      <h1 style={{textAlign: 'center', margin: '40px 0'}}>Blog</h1>
      <div className='blog-section'>
          <div className='image-blog'>
            <div className='half-blog-post'></div>
            <div className='align-content-post'>
              <h2>Bilantul contabil</h2>
              <p>Lorem ipsum dolor sit amet conse.</p>
            </div>
          </div>

          <div className='image-blog'>
            <div className='half-blog-post'></div>
            <div className='align-content-post'>
              <h2>Patrimoniul</h2>
              <p>Lorem ipsum dolor sit amet conse.</p>
            </div>
          </div>
      </div>
    </div>

    <div>
      <div className='blog-section'>
          <div className='image-blog'>
            <div className='half-blog-post'></div>
            <div className='align-content-post'>
              <h2>Obiectul contabilitatii</h2>
              <p>Lorem ipsum dolor sit amet conse.</p>
            </div>
          </div>

          <div className='image-blog'>
            <div className='half-blog-post'></div>
            <div className='align-content-post'>
              <h2>Partile contului</h2>
              <p>Lorem ipsum dolor sit amet conse.</p>
            </div>
          </div>
      </div>
    </div>

    <div>
      <div className='blog-section'>
          <div className='image-blog'>
            <div className='half-blog-post'></div>
            <div className='align-content-post'>
              <h2>Balanta de verificare</h2>
              <p>Lorem ipsum dolor sit amet conse.</p>
            </div>
          </div>

          <div className='image-blog'>
            <div className='half-blog-post'></div>
            <div className='align-content-post'>
              <h2>Dubla inregistrare</h2>
              <p>Lorem ipsum dolor sit amet conse.</p>
            </div>
          </div>
      </div>
    </div>

    <div>
      <div className='blog-section'>
          <div className='image-blog'>
            <div className='half-blog-post'></div>
            <div className='align-content-post'>
              <h2>Hello world!3</h2>
              <p>Lorem ipsum dolor sit amet conse.</p>
            </div>
          </div>

          <div className='image-blog'>
            <div className='half-blog-post'></div>
            <div className='align-content-post'>
              <h2>Hello world!4</h2>
              <p>Lorem ipsum dolor sit amet conse.</p>
            </div>
          </div>
      </div>
    </div>

    <div>
      <div className='blog-section'>
          <div className='image-blog'>
            <div className='half-blog-post'></div>
            <div className='align-content-post'>
              <h2>Hello world!3</h2>
              <p>Lorem ipsum dolor sit amet conse.</p>
            </div>
          </div>

          <div className='image-blog'>
            <div className='half-blog-post'></div>
            <div className='align-content-post'>
              <h2>Hello world!4</h2>
              <p>Lorem ipsum dolor sit amet conse.</p>
            </div>
          </div>
      </div>
    </div>

    <div className='number-blog-page'>
      <h5 className='item-number-blog-page' style={{pointerEvents:'none', opacity: '0.5'}}>{'<'}</h5>
      <h5 className='item-number-blog-page'>1</h5>
      <h5 className='item-number-blog-page'>2</h5>
      <h5 className='item-number-blog-page'>3</h5>
      <h5 className='item-number-blog-page'>{'>'}</h5>
    </div>
    </>
  )
}

export default Blog;