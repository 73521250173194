import { Link } from 'react-router-dom';
import photo1 from '../imagesHome/calc.png';
import './cursuri.css';
const Cursuri = () => {
  return (
   
    <div className="container-select-course">
      
          <Link to='/showlessons' className="item-course">
            <div className='item-lesson-presentation'>
              <h3 className="my-auto">Bazele contabilitatii</h3>
              <div>
                <img src={photo1} alt='img' className='img-lesson-presentation' />
              </div>
            </div>
            <p className='p-3'>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad quia ut perferendis voluptatibus doloribus quod commodi! Animi saepe, mollitia illo, laudantium vel ea autem doloremque maiores sed, pariatur modi sequi.</p>
              <div className='item-lesson-presentation'>
                <button className='button-slow-disp-lesson'>160 de lectii</button>
                <div className='begin-lessons'>30 / 160</div>
              </div>
          </Link>
       
        <div className="item-course">
          <div className='item-lesson-presentation'>
            <h3 className="my-auto title-leson">Contabilitate financiara</h3>
            <div>
              <img src={photo1} alt='img' className='img-lesson-presentation' />
            </div>
          </div>
          <p className='p-3'>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad quia ut perferendis voluptatibus doloribus quod commodi! Animi saepe, mollitia illo, laudantium vel ea autem doloremque maiores sed, pariatur modi sequi.</p>
            <div className='item-lesson-presentation'>
              <button className='button-slow-disp-lesson'>nr. lectii</button>
              <div className='begin-lessons'>Lectii incepute</div>
            </div>
        </div>
    </div>
    
  )
}

export default Cursuri;