
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import './premium.css';
const ExitOffer = () => {

    const navigate = useNavigate();
    const navToHome = () => {
        navigate('/')
    }
  return (
    <div className='x-dist-premium' onClick={navToHome}>
        <FontAwesomeIcon icon={faTimes} className='exit-premium' />
    </div>
  )
}

export default ExitOffer;