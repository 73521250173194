import './premium.css';
import Logo from '../../header/images/logo-contalearn.png';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCheck } from '@fortawesome/free-solid-svg-icons'; // Importăm faTimes pentru X
import ExitOffer from './ExitOffer';

const Paymentpage = () => {
  return (
    <div className='cadru-premium-step-two'>
      <div className='header-prem-top'>
        <div className='header-premium'>
          <div className='logo-dist-premium'>
            <img src={Logo} alt='logo-premium' className='logo-premuim' />
          </div>
          <ExitOffer />
        </div>
      </div>
      <div className='info-cadru-premium'>
        <div className='w-100'>
          <div className='header-premuim-page'>
            <h1>Detalii de plata</h1>
          </div>

        <div className='premium-second-page'>
              <div className='mx-3'>
                <div className='item-space-offer'>Acces complet la toate lecțiile</div>
                <div className='item-space-offer'>Acces la lecțiile de practică</div>
                <div className='item-space-offer'>Acces la teste la finalul capitolelor</div>
                <div className='item-space-offer'>Certificat de finalizare a lecțiilor</div>
                <div className='item-space-offer'>Acces complet la toate lecțiile</div>
                <div className='item-space-offer'>Acces la lecțiile de practică</div>
                <div className='item-space-offer'>Acces la teste la finalul capitolelor</div>
              </div>
              <div className='mx-2'>
                <div className='item-space-offer'><FontAwesomeIcon icon={faCheck} className='item-bifa' /></div>
                <div className='item-space-offer'><FontAwesomeIcon icon={faTimes} className='item-x' /></div>
                <div className='item-space-offer'><FontAwesomeIcon icon={faTimes} className='item-x' /></div>
                <div className='item-space-offer'><FontAwesomeIcon icon={faTimes} className='item-x' /></div>
                <div className='item-space-offer'><FontAwesomeIcon icon={faTimes} className='item-x' /></div>
                <div className='item-space-offer'><FontAwesomeIcon icon={faTimes} className='item-x' /></div>
                <div className='item-space-offer'><FontAwesomeIcon icon={faTimes} className='item-x' /></div>
              </div>
            </div>

        </div>
       
        <button className='button-premium-step-two'>Plateste</button>
        
      </div>
    </div>
  )
}

export default Paymentpage;