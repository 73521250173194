import './lessonprogress.css';

const LessonProgress = () => {
  return (
    <div className='progres-lesson'>
        <div className='procent-lesson-show-lwsson'>0%</div>
    </div> 
  )
}

export default LessonProgress;