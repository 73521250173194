import React from 'react'

const FAQ = () => {
  return (
    <div className='general-container-footer-info'>
        <div className='info-footer-container'>
            <h3>Intrebari si raspunsuri frecvente</h3>
            <div>
                <h4>Ce este contaLearn</h4>
                <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ipsum animi debitis illo soluta rem earum officiis, incidunt eligendi perspiciatis! Eius minima nisi corrupti architecto voluptates rerum quo possimus eum corporis.</p>
            </div>

            <div>
                <h4>Intrebare 1</h4>
                <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ipsum animi debitis illo soluta rem earum officiis, incidunt eligendi perspiciatis! Eius minima nisi corrupti architecto voluptates rerum quo possimus eum corporis.</p>
            </div>

            <div>
                <h4>Intrebare 1</h4>
                <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ipsum animi debitis illo soluta rem earum officiis, incidunt eligendi perspiciatis! Eius minima nisi corrupti architecto voluptates rerum quo possimus eum corporis.</p>
            </div>

            <div>
                <h4>Intrebare 1</h4>
                <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ipsum animi debitis illo soluta rem earum officiis, incidunt eligendi perspiciatis! Eius minima nisi corrupti architecto voluptates rerum quo possimus eum corporis.</p>
            </div>
        </div>
    </div>
  )
}

export default FAQ