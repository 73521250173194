import './premium.css';
import Logo from '../../header/images/logo-contalearn.png';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCheck } from '@fortawesome/free-solid-svg-icons'; // Importăm faTimes pentru X
import ExitOffer from './ExitOffer';

const OfferDiscount = () => {
  return (
    <div className='cadru-premium-step-two'>
      <div className='header-prem-top'>
        <div className='header-premium'>
          <div className='logo-dist-premium'>
            <img src={Logo} alt='logo-premium' className='logo-premuim' />
          </div>
          <ExitOffer />
        </div>
      </div>
      <div className='info-cadru-premium'>
        <div className='w-100'>
          <div className='header-premuim-page'>
            <h1>Cumpara acum si primesti 25% discount la planul anul
            </h1>
          </div>
<h4>Pret plan anual 184.25 lei <span style={{textDecoration: 'line-through'}}>{'(249 lei)'}</span></h4>
        <div className='premium-second-page'>
              <div className='mx-3'>
                <div className='item-space-offer'>Acces complet la toate lecțiile</div>
                <div className='item-space-offer'>Acces la lecțiile de practică</div>
               
              </div>
              <div className='mx-2'>
                <div className='item-space-offer'><FontAwesomeIcon icon={faCheck} className='item-bifa' /></div>
                <div className='item-space-offer'><FontAwesomeIcon icon={faTimes} className='item-x' /></div>

              </div>
            </div>

        </div>
        <Link to='/facturare' className='css-link-offer' >
        <button className='button-premium-step-two'>CUMPARA CU DISCOUNT 25%</button>
        </Link>
        
      </div>
    </div>
  )
}

export default OfferDiscount;