import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from './AuthContext';

  // Adaugă URL-ul API-ului folosind variabila de mediu
  const apiUrl = process.env.REACT_APP_API_URL;

  

const ProtectedRoute = ({ element: Component }) => {
  const { isAuthenticated, dispatch } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(true);

  // Verifică autentificarea la montarea componentului
  useEffect(() => {
    const checkAuth = async () => {
      try {
        const response = await fetch(`${apiUrl}/check-auth`, {
          method: 'GET',
          credentials: 'include', // Asigură-te că trimiți cookie-urile către server
        });

        if (response.ok) {
          const data = await response.json();
          dispatch({ type: 'LOGIN', payload: data.token });
        } else {
          dispatch({ type: 'LOGOUT' }); // Token invalid sau expirat, logout
        }
      } catch (error) {
        console.error('Eroare la verificarea autentificării:', error);
        dispatch({ type: 'LOGOUT' });
      } finally {
        setIsLoading(false); // După ce verificăm autentificarea, setăm `isLoading` la false
      }
    };

    checkAuth(); // Apelează funcția la montare
  }, [dispatch]);

  // Dacă încă se verifică autentificarea, afișăm un ecran de încărcare
  if (isLoading) {
    return <div>Se verifică autentificarea...</div>;  // sau un spinner/loading indicator
  }

  // Dacă utilizatorul este autentificat, returnăm componenta protejată
  return isAuthenticated ? Component : <div>Nu ai acces la această pagină</div>;
};

export default ProtectedRoute;
