
import '../content/registrer.css';
const Ajutor = () => {
  return (
    <div className='container-ajotor'>
      
      <div className='vh-100'>
      <form className="registrer-form mt-4" >
        <h2 className='registrer-title fs-1'>Lasa-ne un mesaj!</h2>
        <label htmlFor="nume" className="name-elemnts-form-reg"></label>
        <input
          type="text"
          id="nume"
          name="nume"
          className="input-reg"
          placeholder="Nume si prenume"
          
        
          required
        />

        <label htmlFor="email" className="name-elemnts-form-reg"></label>
        <input
          type="email"
          id="email"
          name="email"
         
          className="input-reg"
          placeholder="Introdu adresa de email!"
         
          required
        />
       
        <label htmlFor="message" className="name-elemnts-form-reg"></label>
        <textarea className="input-reg" placeholder='Scrie aici mesajul tau!'>
          
        </textarea>
        <button type="submit" className="input-reg-submit">
          Trimite!
        
        </button>
      </form>
      <div className='date-societate'>
        <h4 className='item-contact-help'>DOALCO BUSINESS SRL</h4>
        <h6 className='item-contact-help'>CUI: 43296148</h6>
        <h6 className='item-contact-help'>Nr. Inmatriculare: J16/2025/2020 </h6>
        <h6 className='item-contact-help'>Adresa: Str. C-tin Brancusi nr. 48, Oras Dabuleni, Jud. Dolj, Romania</h6>
        <div className='group-contact-elements'>
          <h6 className='item-contact-help'>Facebook: <a href="mailto:info@contalearn.ro">Facebook</a></h6>
          <h6 className='item-contact-help' >Instagram: <a href="mailto:info@contalearn.ro">Instagram</a></h6>
          <h6 className='item-contact-help'>Linkedin: <a href="mailto:info@contalearn.ro">Linkedid</a></h6>
        </div>

        <h5 className='item-contact-help'>Parteneriate</h5>
        <h6 className='item-contact-help'>Contact: <a href="mailto:info@contalearn.ro">info@contalearn.ro</a></h6>
        <h6 className='item-contact-help'>Telefon: <a href="tel:+40740392270">+40740392270</a></h6>

      </div>

    </div>
     
    </div>
  )
}

export default Ajutor