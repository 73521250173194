import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWebAwesome, faBlogger } from '@fortawesome/free-brands-svg-icons';
import {
  faSignOutAlt,
  faRocket,
  faHouse,
  faBook,
  faCircleUser,
  faUserPlus,
  faChevronRight,
  faChevronLeft,
  faUser,
  faCog,
  faQuestionCircle,
} from '@fortawesome/free-solid-svg-icons';
import { AuthContext } from '../AuthContext';
import { faUser as faUserRegular } from '@fortawesome/free-regular-svg-icons';
import './menu.css';


// Adaugă URL-ul API-ului folosind variabila de mediu
const apiUrl = process.env.REACT_APP_API_URL;


export const Menu = () => {
  const { isAuthenticated } = useContext(AuthContext);
  const [menuMobOpen, setMenuMobOpen] = useState(false); // Stare pentru meniul mobil
  const location = useLocation();
  const { dispatch } = useContext(AuthContext);
  const [userData, setUserData] = useState({});
  const menuRef = useRef(null);
  const toggleButtonRef = useRef(null); // Referință pentru butonul de toggle

  const handleLogout = () => {
    dispatch({ type: 'LOGOUT' });
  };

  const [changeSecondMenu, setChangeSecondMenu] = useState(true);
  const openSecondMenu = () => {
    setChangeSecondMenu(!changeSecondMenu);
  };

  const navigate = useNavigate();

  const goToSetari = () => navigate('/setari');
  const goToAjutor = () => navigate('/ajutor');
  const goToDashbord = () => {
    setChangeSecondMenu(!changeSecondMenu);
    navigate('/userpage');
  };

  // Funcția pentru toggle
  const handleMenuMobile = () => {
    setMenuMobOpen(prevState => !prevState);
  };

  // Închide meniul dacă se face click în afara meniului sau pe toggle
  useEffect(() => {
    const handleClickOutside = event => {
      if (
        menuRef.current && 
        !menuRef.current.contains(event.target) &&
        !toggleButtonRef.current.contains(event.target) // Exceptăm butonul de toggle
      ) {
        setMenuMobOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  // Închide meniul la schimbarea de rută
  useEffect(() => {
    setMenuMobOpen(false);
  }, [location]);

  // Fetch pentru a obține datele utilizatorului
  useEffect(() => {
    const fetchUserData = async () => {
      if (isAuthenticated) {
        try {
          const response = await fetch(`${apiUrl}/user`, {
            method: 'GET',
            credentials: 'include',
          });
          const data = await response.json();
          setUserData(data);
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      }
    };
    fetchUserData();
  }, [isAuthenticated]);

  return (
    <>
      <div className='menu-items'>
        {isAuthenticated ? (
          <>
            <Link to='/userpage' className='name-menu-item'>
              <FontAwesomeIcon icon={faUser} className="mx-1" />
              Parcurs
            </Link>
            <Link to='/cursuri' className='name-menu-item'>
              <FontAwesomeIcon icon={faBook} className='mx-1' />
              Cursuri
            </Link>
            <Link to='/blog' className='name-menu-item'>
              <FontAwesomeIcon icon={faBlogger} className="mx-1" />
              Blog
            </Link>
          </>
        ) : (
          <>
            <Link to='/' className='name-menu-item'>
              <FontAwesomeIcon icon={faHouse} className="mx-1" />
              Acasa
            </Link>
            <Link to='/price' className='name-menu-item'>
              <FontAwesomeIcon icon={faRocket} className='mx-1' />
              Start
            </Link>
            <Link to='/blog' className='name-menu-item'>
              <FontAwesomeIcon icon={faBlogger} className="mx-1" />
              Blog
            </Link>
          </>
        )}
        {isAuthenticated ? (
          <Link to='/premium' className='name-menu-item-desktop-premium' onClick={() => setMenuMobOpen(false)}>
            <FontAwesomeIcon icon={faWebAwesome} className='mx-1' />
            Premium
          </Link>
        ) : (
          <>
            <Link to='/login' className='login-item-desktop'>
              <FontAwesomeIcon icon={faCircleUser} className="mx-1" />
              Log in
            </Link>
            <Link to='/registrer' className='signup-item-desktop'>
              <FontAwesomeIcon icon={faUserPlus} className="mx-1" />
              Sign up
            </Link>
          </>
        )}
      </div>

      {/* Toggle button pentru meniul mobil */}
      <div ref={toggleButtonRef} className='mobile-menu' onClick={handleMenuMobile}>
        <div className={menuMobOpen ? 'item-mobile-ball' : 'item-mobile-ball-open'}></div>
        <div className={menuMobOpen ? 'item-mobile-ball' : 'item-mobile-ball-open'}></div>
        <div className={menuMobOpen ? 'item-mobile-ball' : 'item-mobile-ball-open'}></div>
      </div>

      {/* Conținutul meniului mobil */}
      <div ref={menuRef} className={menuMobOpen ? 'mob-menu-open' : 'mob-menu-open-none'}>
        {/* Elementele meniului mobil */}
        {isAuthenticated ? (
          <>
            <div className={changeSecondMenu ? 'show-second-menu' : 'hide-second-menu'}>
              <Link to='/userpage' className='name-menu-item-mob' onClick={() => setMenuMobOpen(false)}>
                <FontAwesomeIcon icon={faUser} className="mx-1" />
                Parcurs
              </Link>
              <Link to='/cursuri' className='name-menu-item-mob' onClick={() => setMenuMobOpen(false)}>
                <FontAwesomeIcon icon={faBook} className='mx-1' />
                Cursuri
              </Link>
              <Link to='/blog' className='name-menu-item-mob' onClick={() => setMenuMobOpen(false)}>
                <FontAwesomeIcon icon={faBlogger} className="mx-1" />
                Blog
              </Link>

              <button className='user-menu-button' onClick={openSecondMenu}>
                <div className='user-submenu-elements'>
                  {userData.profile_picture ? (
                    <img
                      src={userData.profile_picture}
                      alt='user'
                      className='user-image-general-menu'
                    />
                  ) : (
                    <div className='default-profile-icon-submenu'>
                      <FontAwesomeIcon icon={faUserRegular} className='image-profile-user'/>
                    </div>
                  )}
                  <div>{userData.username || 'Nume Utilizator'}</div>
                </div>
                <FontAwesomeIcon icon={faChevronRight} className='arrow-sumbenu-user' />
              </button>

              <Link to='/premium' className='name-menu-item-mob-premium' onClick={() => setMenuMobOpen(false)}>
                <FontAwesomeIcon icon={faWebAwesome} className='mx-1' />
                Premium
              </Link>
            </div>
            <div className={changeSecondMenu ? 'hide-second-menu' : 'show-second-menu'}>
              <div onClick={() => setChangeSecondMenu(!changeSecondMenu)} className='name-menu-item-mob-back'>
                <FontAwesomeIcon icon={faChevronLeft} className='arrow-sumbenu-user' />
                Back
              </div>

              <button className='user-menu-button' onClick={goToDashbord}>
                <div className='user-submenu-elements'>
                  {userData.profile_picture ? (
                    <img
                      src={userData.profile_picture}
                      alt='user'
                      className='user-image-general-menu'
                    />
                  ) : (
                    <div className='default-profile-icon-submenu'>
                      <FontAwesomeIcon icon={faUserRegular} className='image-profile-user' />
                    </div>
                  )}
                  <div>Profilul meu</div>
                </div>
              </button>


              <div className='name-menu-item-mob' onClick={goToSetari}>
                <FontAwesomeIcon icon={faCog} />
                Setari
              </div>
              <div className='name-menu-item-mob' onClick={goToAjutor}>
                <FontAwesomeIcon icon={faQuestionCircle} />
                Ajutor
              </div>

              <button onClick={handleLogout} className='name-menu-item-mob'>
                <FontAwesomeIcon icon={faSignOutAlt} />
                Logout
              </button>
            </div>
          </>
        ) : (
          <>
            <Link to='/' className='name-menu-item-mob' onClick={() => setMenuMobOpen(false)}>
              <FontAwesomeIcon icon={faHouse} className="mx-1" />
              Acasa
            </Link>
            <Link to='/price' className='name-menu-item-mob' onClick={() => setMenuMobOpen(false)}>
              <FontAwesomeIcon icon={faRocket} className='mx-1' />
              Start
            </Link>
            <Link to='/blog' className='name-menu-item-mob' onClick={() => setMenuMobOpen(false)}>
              <FontAwesomeIcon icon={faBlogger} className="mx-1" />
              Blog
            </Link>

            <Link to='/login' className='form-menu-tabel design-ligin' onClick={() => setMenuMobOpen(false)}>
              <FontAwesomeIcon icon={faCircleUser} className="mx-1" />
              Log in
            </Link>
            <Link to='/registrer' className='form-menu-tabel design-inregistrare' onClick={() => setMenuMobOpen(false)}>
              <FontAwesomeIcon icon={faUserPlus} className="mx-1" />
              Sign up
            </Link>
          </>
        )}
      </div>
    </>
  );
};
