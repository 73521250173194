import './registrer.css';
import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom'; // Pentru redirecționare
import { AuthContext } from '../AuthContext'; // Importăm contextul de autentificare
import { Link } from 'react-router-dom';
import SocialLogin from './SocialLogin';

  // Adaugă URL-ul API-ului folosind variabila de mediu
  const apiUrl = process.env.REACT_APP_API_URL;

export const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false); // Pentru starea de încărcare

  const navigate = useNavigate(); // Hook pentru redirecționare
  const { dispatch } = useContext(AuthContext); // Folosim dispatch din contextul global

  const handleLogin = (e) => {
    e.preventDefault();
    setIsLoading(true); // Activează starea de încărcare

    const userData = {
      email,
      password,
    };

    fetch(`${apiUrl}/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(userData),
      credentials: 'include', // Asigură-te că browserul trimite și primește cookies
    })
      .then((response) => {
        if (!response.ok) {
          return response.json().then((errorData) => {
            throw new Error(errorData.message || 'A apărut o eroare la autentificare');
          });
        }
        return response.json();
      })
      .then((data) => {
        dispatch({ type: 'LOGIN' }); // Actualizăm starea de autentificare
        navigate('/userpage'); // Redirecționează către pagina de utilizator după autentificare
      })
      .catch((error) => {
        console.error('Error:', error);
        setErrorMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false); // Dezactivează starea de încărcare
      });
  };





  return (
    <div className='container-login'>
      <form className='registrer-form mt-4' onSubmit={handleLogin}>
        <h2 className='registrer-title fs-1'>Conectează-te!</h2>
        <label htmlFor='email' className='name-elemnts-form-reg'></label>
        <input
          type='email'
          id='email'
          name='email'
          className='input-reg'
          placeholder='Introdu emailul de logare'
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <label htmlFor='password' className='name-elemnts-form-reg'></label>
        <input
          type='password'
          id='password'
          name='password'
          className='input-reg'
          placeholder='Introdu parola'
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <div className='forgot-password'>
          <b>Ai uitat parola?</b>
        </div>
        
        <button type='submit' className='input-reg-submit' disabled={isLoading}>
          {isLoading ? 'Se autentifică...' : 'Log in'}
        </button>
      </form>
      <b className='options-connect-sau'>SAU</b>
      <div className='options-connect'>
        <SocialLogin />
      </div>

      <div className='redirect-registrer'>
        <b className='redirect-reg-text'>Nou pe Contalearn?</b>
        <Link to='/registrer' className='redirect-reg-link'>
          <b className='redirect-reg-link'>Creeaza un cont</b>
        </Link>
      </div>

      {errorMessage && (
        <div className="error-message">
          {errorMessage}
        </div>
      )}
    </div>
  );
};
