import { Menu } from './Menu';
import { Logo } from './Logo';
import UserMenu from './UserMenu';


import React, { useContext } from 'react';
import { AuthContext } from '../AuthContext'; // Importăm contextul de autentificare



import './header.css';

export const Header = () => {

const { isAuthenticated } = useContext(AuthContext);


  return (
    <div className='align-header-elements'>
      
        
        <Logo />
        <Menu />
        {isAuthenticated ? (
          <UserMenu />
        ):(false)}
      </div>
  )

}
